<template>
  <div>
    <!-- 创建缺卡需求 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa' }">
      创建缺卡需求
    </van-divider>
    <div>
      <van-field
        label="项目ID"
        v-model="search"
        placeholder="请输入项目ID或项目名"
      >
        <template #button>
          <van-button size="small" type="primary" @click="onsearch"
            >搜索项目</van-button
          >
        </template>
      </van-field>
      <!-- 选择项目 -->
      <van-field
        readonly
        clickable
        name="picker"
        :value="project"
        label="选择项目"
        placeholder="点击选择项目"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field v-model="price" type="number" label="价格:" />
      <van-field v-model="demant" type="number" label="日需求量:" />
      <!-- 卡类型 -->
      <van-field name="radio" label="需要的卡类型">
        <template #input>
          <van-radio-group v-model="card_type" direction="horizontal">
            <van-radio name="0">不限</van-radio>
            <van-radio name="1">实卡</van-radio>
            <van-radio name="2">虚卡</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="annotation"
        rows="2"
        autosize
        label="需求备注"
        type="textarea"
        maxlength="200"
        placeholder="请输入备注"
        show-word-limit
      />
      <div style="text-align: center">
        <van-button type="info" @click="creat">创建缺卡需求</van-button>
      </div>
    </div>
    <!-- 我的需求列表 -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa' }">
      我的需求列表
    </van-divider>
    <div class="grid-container">
      <div v-for="item in items" :key="item.id" class="grid-item">
        <p>对接码:{{ item.key_ }}</p>
        <p>项目:{{ item.project_id }}</p>
        <p>建议酬劳:{{ item.price }}</p>
        <p v-if="item.card_type == '0'">号段:不限</p>
        <p v-if="item.card_type == '1'">号段:实卡</p>
        <p v-if="item.card_type == '2'">号段:虚卡</p>
        <p>需求量:{{ item.demant }}</p>
        <p>到期时间:{{ item.time }}</p>
        <p>备注:{{ item.annotation }}</p>
        <p style="text-align: center">
          <van-button type="warning" @click="del(item.key_)"
            >删除此需求</van-button
          >
        </p>
      </div>

      <div style="height: 50px"></div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      search: "",
      showPicker: false,
      items: [],
      card_type: "0",
      project: "",
      project_id: "",
      price: "",
      demant: "",
      annotation: "",
      //项目列表
      columns: [],
      columns2: [],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async onsearch() {
      if (this.search == "") {
        return;
      }
      let res = await this.$api.fn_user_project_seach(this.search);
      res = res.data;
      if (typeof res == "string") {
        res = res.replace("\\/g", "");
        res = eval("(" + res + ")");
      }
      res = res.data;
      this.columns = [];
      res.forEach((element) => {
        this.columns.push(element.name);
      });
      this.columns2 = res;
    },
    onConfirm(value) {
      this.project = value;
      this.showPicker = false;
      this.columns2.forEach((element) => {
        if (element.name == value) {
          this.project_id = element.id;
          return;
        }
      });
    },
    async refresh() {
      let res = await this.$api.fn_user_demant_getList();
      res = res.data;
      this.items = res.data;
      for (let index = 0; index <  this.items.length; index++) {
       const date = new Date(parseInt(this.items[index].time) * 1000 - 3600000 * 8);
       date.setDate(date.getDate() + 3);
       this.items[index].time= date.toLocaleString();
      }
    },
    async del(key) {
      let res = await this.$api.user_demant_delect(key);
      res = res.data;
      if (res.message == "ok") {
        this.refresh()
      } else {
        this.$toast(res.message);
      }

    },
    async creat(){
      let res = await this.$api.fn_user_demant_creat(this.card_type,this.price,this.annotation,this.demant,this.project_id);
      res = res.data;
      if (res.message == "ok") {
       this.refresh()
      } else {
        this.$toast(res.message);
      }
    }
  },
};
</script>
  
  <style scoped>
.grid-container {
  font-size: 0.6rem;
}
p{
  margin-bottom: -6px;
}

.grid-item {
  border: 1px solid #ccc;
  padding: 10px;
  word-wrap: break-word;
}

.label {
  font-weight: bold;
}

.value {
  white-space: pre-wrap;
}
</style>
  